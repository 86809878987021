<template>

	<div>


    <rxNavBar title="我的业绩" androidOrIOSFlag="true"></rxNavBar>
    <van-tabs
        :border="false"
        title-style="z-index:998;"
        sticky
        title-active-color="white"
        title-inactive-color="#FFE1C7"
        color="white"
        line-width="40px"
        line-height="4px"
        background="linear-gradient(to right, #ffc274, #ff5d3b)"
        v-model="name"
        @change="tabs"
    >
      <van-tab title="业绩" name="achievement" ></van-tab>

      <van-tab title="佣金" name="commission" ></van-tab>

    </van-tabs>

		<div >
			<!--选择月份-->
			<div class="part-inputpart" style="margin-top: 10px">
				<div id="expectedDatePanel" class="part-inputpart-row" @click="clickExpectedDateShow">
					<span :class=" 0==houseInfo.expectedDate.trim().length ? 'content-none' : 'content-have' "></span>
					<span class="part-inputpart-row-enablenon part-inputpart-row-header">选择月份</span>
					<span class="content-divide">|</span>
					<span class="part-inputpart-row-graytext" :class="0==houseInfo.expectedDate ? '': 'part-inputpart-row-redtext' ">{{houseInfo.expectedDate}}</span>
					<img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png" >
<!--					<img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/wamp.png" v-if="name == 'commission'">-->
				</div>
				<div>
					<van-popup v-model="totalResultShow" position="bottom" class="selectTime">
						<div>
							<van-datetime-picker v-model="currentDate" type="year-month" title="选择年月日" item-height="35px"
							 @confirm="expectedDateConfirm" @cancel="expectedDateCancel" />
						</div>
					</van-popup>
				</div>
			</div>
			<!--总成绩-->
			<div class="big">

				<div>
					<img src="../../../assets/images/up.png" class="up" v-if="name == 'achievement'">
					<img src="../../../assets/images/wamp.png" class="up" v-if="name == 'commission'" >
				</div>
				<div>
					<span class="money">{{houseInfo.money}}元</span>
				</div>
				<div>
					<p v-if="name == 'achievement'"><span class="total">{{houseInfo.month}}月总业绩</span></p>
					<p v-if="name == 'commission'"><span class="total">{{houseInfo.month}}月总佣金</span></p>
				</div>

			</div>
            <!-- 搜索区域和类型区域-->
            <div class="searchAndType">
                <!-- 类型区域-->
                <div class="types">
                    <span class="type" v-if="performanceTypeList[performanceNumber]" @click="isPerformanceShow = !isPerformanceShow" id="downList">{{performanceTypeList[performanceNumber].dictionaryTitle}}</span>
                    <div :class="isPerformanceShow?'small-icon-rotate':'small-icon'"></div>
                </div>
            </div>
            <van-popup v-model="isPerformanceShow" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="performanceTypeList"
                    @cancel="isPerformanceShow = false"
                    :default-index="performanceNumber"
                    value-key="dictionaryTitle"
                    @confirm="changePerformance"
                />
            </van-popup>
			<div class="reviews" style="background-color: #F8F8F8">
				<!--收房列表-->
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list  v-model="loading" :finished="finished" loading-text="加载中" finished-text="已经到底了" @load="onLoad" v-if="!swi">
						<div class="review" v-for="(item,index) in results" :key="index">
							<div :class="item.achievementStatus=='0' ? 'red': item.achievementStatus=='1' ? 'orange':'green'" >
								{{item.achievementTypeStr}}</div>
							<!--房子地址-->
							<div class="position">
								<span class="position-span-left">{{item.houseTypeStr}}<span>·</span></span>
								<span class="position-span-right">{{item.roomDetailedAddress}}</span>
							</div>
							<div class="demand" @click="skipDetails(item)">
								<div class="demand-reason" style="margin-top: 17px"><span>房源编号：</span><span style="font-weight: normal;color: #666666">{{item.houseCode}}</span></div>
								<div class="demand-reason"><span>合同编号：</span><span style="font-weight: normal;color: #666666">{{item.contractCode}}</span></div>
								<div v-if="name == 'achievement'" class="demand-reason"><span>{{item.type}}：</span><span style="font-weight: normal;color: #666666">{{item.achievementCount ? item.achievementCount : '暂无'}}</span></div>
								<div  v-if="name == 'commission'" class="demand-reason"><span>佣金金额：</span><span style="font-weight: normal;color: #666666">{{item.achievementCount ? item.achievementCount : '暂无'}}元</span></div>
								<!--<div class="demand-reason" style="margin-bottom: 17px"><span>实际结算日：</span><span style="font-weight: normal;color: #666666">{{item.achievementSettleTime ? item.achievementSettleTime : '暂无'}}</span></div>-->
							</div>
						</div>
					</van-list>
				</van-pull-refresh>


			</div>

		</div>
	</div>
</template>

<script>
	import {
		NavBar,
		DatetimePicker,
		List,
		PullRefresh,
		Popup,
		Picker,
    Tab,
    Tabs,
	} from 'vant'
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
	import {browseStaffAchievementList,queryStaffAchievementCount,getQueryBaseData} from "../../../getData/getData";


	export default {

		components: {
			[NavBar.name]: NavBar,
			[DatetimePicker.name]: DatetimePicker,
			[List.name]: List,
			rxNavBar,
			[PullRefresh.name]: PullRefresh,
			[List.name]: List,
			[Popup.name]: Popup,
			[Picker.name]: Picker,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
		},

		mounted(){
            var date = new Date()
            if (date.getMonth() < 9) {
                this.houseInfo.expectedDate = date.getFullYear() + '-' + '0' + (date.getMonth() + 1)

            } else {
                this.houseInfo.expectedDate = date.getFullYear() + '-' + (date.getMonth() + 1)
            }
			this.initDictionaryData();
		},


		data() {
			return {
        // eslint-disable-next-line no-mixed-spaces-and-tabs
        name:'achievement',
				loading: false,
				finished: false,
				refreshing: false,
				currentPage: 1,
				numberPage: 5,
				pageCount: '',
				totalPage: '',
				swi:true,

				totalResultShow: false,
				// 日期
				isExpectedDateShow: false, //函数
				currentDate: new Date(), //函数

				/*参数*/
				houseInfo: {
					expectedDate: '',
					money: '0',
					month: new Date().getMonth()+1
				},
				results: [],
				performanceNumber:'0',     //业绩类型id
				performanceTypeList:[],  //业绩类型集合
				isPerformanceShow:false

			}
		},

created() {
  this.initData()
  this.initCountData()
},

    methods: {

      tabs(){
        this.currentPage = 1
        this.results = []
        this.initData()
        this.initCountData()
      },
			onRefresh() {
				// 清空列表数据
				this.finished = false;
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				this.onLoad();
			},
			onLoad() {
				setTimeout(() => {
					if (this.refreshing) {
						this.results = [];
						this.refreshing = false;
						this.currentPage = 1
					}
					if (this.currentPage <= this.totalPage) {
						this.initData()
					}
					this.loading = false;
					if (this.results.length >= this.pageCount) {
						this.finished = true;
					}
				}, 1000);
			},
			//初始化加载数据
			initData(){
				let that = this;
				let initData = {};
				let name = this.name
				if (name == 'commission'){
          initData.staffAchievementType = 1
        }
				initData.user_id = getStaffId();
				initData.staffId = getStaffId();
				initData.currentPage = that.currentPage;
				initData.numberPage = that.numberPage;
                initData.expectedDate = that.houseInfo.expectedDate;
				initData.achievementType=that.performanceTypeList[that.performanceNumber].dictionaryValue;
				initData.achievementStatus = 2
				browseStaffAchievementList(initData).then(response => {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.data.pageCount == 0) {
							that.swi = true
							that.loading = false;
							that.finished = true;
						}else if(response.data.data.data&&response.data.data.data.length==0){
							that.loading = false;
							that.finished = true;
						}else{
							that.swi = false
							this.currentPage++
							let newResults = response.data.data.data
							that.results = that.results.concat(newResults)
							if (that.totalPage == '' && that.pageCount == '') {
								that.totalPage = response.data.data.totalPage
								that.pageCount = response.data.data.pageCount
							}
						}

					});
				});
			},
            initCountData(){
                let that = this;
                let initData = {};
              let name = this.name
              if (name == 'commission'){
                initData.staffAchievementType = 1
              }
                initData.user_id = getStaffId();
                initData.staffId = getStaffId();
                initData.expectedDate = that.houseInfo.expectedDate;
				initData.achievementType=that.performanceTypeList[that.performanceNumber].dictionaryValue;
                queryStaffAchievementCount(initData).then(response => {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.data.achievementCount){
                            that.houseInfo.money = response.data.data.achievementCount;
                        }else {
                            that.houseInfo.money = 0;
						}
                    });
                });
            },
            // 获取字典数据
            initDictionaryData: function () {
                var that = this
                let initData = {}
                initData.user_id = getStaffId()
                initData.fdName = ["ACHIEVEMENTMAP"]
                getQueryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.performanceTypeList = response.data.data.ACHIEVEMENTMAP
						that.performanceTypeList.unshift({dictionaryValue:'',dictionaryTitle:'全部'})
					    that.initData();
                        that.initCountData();
                    })
                })
            },	
			changePerformance(item,index){
				this.performanceNumber=index
				this.isPerformanceShow=false
				this.currentPage=1;
				this.finished = false;
	            this.loading=false;
				this.swi=true;
				this.results=[]
				this.initData();
                this.initCountData();				

			},		
			//跳转详情
			skipDetails(e){
                if(e.achievementType == '0' || e.achievementType == '1'){
                    //首次业绩
                    this.$router.push({
                        name:'firstResultsDetail',
                        query:{
                            id: e.achievement_id,
                            staffId: getStaffId(),
                            staffAchievementDetailId: e.asdId
                        }
                    })
                }else if(e.achievementType == '2'){
					return
                    //中退业绩
                    this.$router.push({
                        name:'middleResultsDetail',
                        query:{
                            id: e.achievement_id,
                            staffId: getStaffId(),
                            staffAchievementDetailId: e.asdId
                        }
                    })
                }else if(e.achievementType == '3'){
                    //二次业绩
                    this.$router.push({
                        name:'secondResultsDetail',
                        query:{
                            id: e.achievement_id,
                            staffId: getStaffId(),
                            staffAchievementDetailId: e.asdId
                        }
                    })
                }else if(e.achievementType == '4' || e.achievementType == '5' ){
                    //空置业绩 首次空置业绩
                    this.$router.push({
                        name:'vacantResultsDetail',
                        query:{
                            id: e.achievement_id,
                            staffId: getStaffId(),
                            staffAchievementDetailId: e.asdId
                        }
                    })
                }else if(e.achievementType == '6'){
                    //租后扣减业绩
                    this.$router.push({
                        name:'afterRentResultsDetail',
                        query:{
                            id: e.achievement_id,
                            staffId: getStaffId(),
                            staffAchievementDetailId: e.asdId
                        }
                    })
                }else if(e.achievementType == '7' || e.achievementType == '8'){
                    //对冲业绩 首次对冲业绩
                    /*this.$router.push({
                        name:'hedgingResultsDetail',
                        query:{
                            id: e.achievement_id,
                            staffId: getStaffId(),
                            staffAchievementDetailId: e.asdId
                        }
                    })*/
                }
			},
			//返回上一层
			leftReturn() {
				this.$router.go(-1);
			},

			/*方法*/
			clickExpectedDateShow() {
				this.totalResultShow = !this.totalResultShow
				this.isExpectedDateShow = !this.isExpectedDateShow;
				var panel = document.getElementById('expectedDatePanel')
				if (panel) {
					document.addEventListener('click', e => {
						if (!panel.contains(e.target)) {
							this.isExpectedDateShow = false
						}
					})
				}
			},


			//日期选择组件 点击确认键后执行方法
			expectedDateConfirm(value) {
				var date = new Date(value)
				if (date.getMonth() < 9) {
					this.houseInfo.expectedDate = date.getFullYear() + '-' + '0' + (date.getMonth() + 1)

				} else {
					this.houseInfo.expectedDate = date.getFullYear() + '-' + (date.getMonth() + 1)
				}
                this.houseInfo.month = date.getMonth() + 1
                this.currentDate = value
				this.isExpectedDateShow = false
				this.totalResultShow = false

				//初始化数据
                this.initPage()
                this.initData();
                this.initCountData();

			},
            initPage() {
                this.loading = false;
                this.finished= false;
                this.refreshing= false;
                this.currentPage= 1;
                this.numberPage= 5;
                this.pageCount= '';
                this.totalPage= '';
                this.swi= true;
                this.totalResultShow=false
				// 日期
                this.isExpectedDateShow= false //函数
                this.results= []
            },

            //日期选择组件 点击取消键后执行方法
			expectedDateCancel() {
				this.isExpectedDateShow = false
				this.totalResultShow = false
			},

		}


	}
</script>

<style scoped>
	/*选择月份的CSS*/
	.part {
		margin: 25px;
	}

	.part-subtitle {
		color: #ff5d3b;
		font-size: 12px;
		font-weight: 600;
	}

	.part-inputpart {
		display: flex;
		flex-direction: column;
		border-radius: 8px;
		white-space: nowrap;
		overflow: hidden;
		font-size: 15px;
	}

	.part-inputpart-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 40px;
		background-color: #ffffff;
		margin-top: 2px;
		width: 335px;
		height: 50px;
		border-radius: 8px;
		margin-left: 20px;
		margin-bottom: 20px;
	}

	.part-inputpart-row-header {
		font-weight: 800;
	}

	.part-inputpart-row input {
		border: 0;
		width: 100%;
	}

	.part-inputpart-dropDownList {
		height: 23%;
		/*垂直方向滚动*/
		/*overflow-y: scroll;*/
		overflow: auto;
		width: 92.5%;
		position: absolute;
		margin-left: 20px;
		height: 185px;
		width: 335px;
		z-index: 99;
	}

	.part-inputpart-row-enablenon {
		/*margin-left: 26px;*/
	}

	.part-inputpart-row-graytext {
		color: #d8d8d8;
		width: 100%;
	}


	.part-inputpart-row-redtext {
		width: 100%;
		color: #ff5d3b;
		font-weight: 600;
	}

	.part-inputpart-row-right {
		float: right;
		margin-right: 10px;
	}


	.part-inputpart-row-right-timepick {
		width: 20px;
	}

	.content-have {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #ff5d3b;
	}

	.content-none {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #c7c7c7;
	}

	.content-divide {
		font-size: 12px;
		color: #efefef;
		margin: 0 10px;
	}

	.content-have {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #ff5d3b;
	}

	.content-none {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #c7c7c7;
		margin-top: 1px;


	}

	/*上升图片的CSS */
	.big {
		width: 335px;
		height: 213px;
		background-color: white;
		margin-left: 20px;
		margin-top: -16px;
		border-radius: 8px;
		position: relative;
	}

	.up {
		width: 45px;
		height: 45px;
		position: absolute;
		left: 45%;
		top: 25.5%;
	}

	.money {
		font-size: 35px;
		font-weight: 600;
		position: absolute;
    padding: 15px;
		left: 50%;
		top: 62%;
		color: #FF5E3B;
		transform: translate(-50%, -50%);
		width: 100%;
		text-align: center;
	}

	/*.money {*/
		/*width: 100%;*/
		/*height: auto;*/
		/*border: 1px solid red;*/
		/*!*line-height: 25px;*!*/
		/*font-size: 35px;*/
		/*font-weight: 600;*/
		/*color: #FF5E3B;*/
	/*}*/
	/*.money-flex{*/
		/*display: flex;*/
		/*align-items: center;*/
		/*justify-content: center;*/
		/*flex-direction: column;*/
	/*}*/
	.total {
		font-size: 13px;
		;
		position: absolute;
		left: 42%;
		top: 80%;
		color: #B6B6B6;

	}

	/*循环部分*/
	.green {
		margin: 0;
		width: 80px;
		height: 17px;
		font-size: 9px;
		text-align: center;
		line-height: 20px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 8px;
		background: linear-gradient(to right, #63cb96, #30d0ac);
		color: #EDFCF5;
	}

	.orange {
		margin: 0;
		width: 43px;
		height: 17px;
		font-size: 9px;
		text-align: center;
		line-height: 20px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 8px;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: #EDFCF5;
	}

	.red {
		margin: 0;
		width: 43px;
		height: 17px;
		font-size: 9px;
		text-align: center;
		line-height: 20px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 8px;
		background: linear-gradient(to right, #FC3F1C, #E22220);
		color: #EDFCF5;
	}

	.reviews {
		width: 100%;
		overflow: hidden;
		height: auto;
		position: relative;
		top: 15px;
		background-color: #fAfAfA;
	}

	.review {
		margin: 0 15px 15px 20px;
		height: auto;
		background-color: white;
		border-radius: 8px;
		overflow: hidden;
		width: 335px;
	}

	.top-title-orange {
		margin: 0;
		width: 45px;
		height: 18px;
		font-size: 10px;
		text-align: center;
		line-height: 20px;
		border-radius: 8px 0;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
	}

	.top-title-green {
		margin: 0;
		width: 45px;
		height: 18px;
		font-size: 10px;
		text-align: center;
		line-height: 20px;
		border-radius: 8px 0;
		background: linear-gradient(to right, #63cb96, #30d0ac);
		color: white;
	}

	.top-title-red {
		margin: 0;
		width: 45px;
		height: 18px;
		font-size: 10px;
		text-align: center;
		line-height: 20px;
		border-radius: 8px 0;
		background: linear-gradient(to right, #FF431B, #E02020);
		color: white;
	}

	.position {
		margin: 5px 15px 15px 17px;
		/*width: 345px;*/
		height: auto;
		text-align: left;
		font-size: 15px;
		font-weight: bold;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-height: 10px;
	}

	.position-span-left {
		line-height: 20px;
		color: #FE5C40;
	}

	.position-span-right {
		line-height: 23px;
		color: black;
	}


	.demand {
		width: 100%;
		height: auto;
		border-top: 1px solid #f4f4f4;
	}

	.demand-reason {
		margin-top: 5px;
		margin-left: 15px;
		color: #222222;
		font-weight: 800;
		font-size: 13px;
    padding-bottom: 15px;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
    .types{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }
    .type{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform:rotate(180deg);
    }		
</style>
